#notFound {
.notFoundHeading{
    font-size: 13.5rem;
    color:#7854f7 ;
    font-weight: bolder;
  }
  .notFoundSubheading{
    font-size: 18.5px;
    opacity: 0.8;
    font-family: Montserrat!important;
    color: #7854f7;
  }
 span{
        font-weight: 700;
        font-size: 20px;
        // color: #7854f7;
    }
  
.backButton:hover{
    font-size: 14px!important;
        font-weight: 500!important;
    }
  
  .mt6rem{
    margin-top: 13rem;
  }
 .mb6rem{
margin-bottom: 13rem;
 }
}
// .purple-background{
//     background-color: #7854f7!important;
//   }