/* Blog Page */
#BlogPage{
    .blog-detail-image .img-fluid{
        width: 38rem!important;
        height: 39rem!important;
      }
      .blog-content-div{
        background: #fff!important;
      }
      .blog-list-card{
        min-height: 350px!important;
      }
      
      .blog-image-div{
      margin: 3% 0%;
      }
      .BlogShare{
        padding-right: 0.5rem;
        display: grid!important;
      }
      .blog-content{
      margin-right: 3.8em;
        margin-bottom: 3em;
      }
      .blog-content .blogPara{
        margin: 2rem 0rem;
      }
      .blog-content strong{
        color: #182026;
      }
      .htmlspan p{
        margin: 0rem 0rem!important;
      }
      .htmlspan li{
        margin: 0.7rem 0rem;
      }
      .htmlspan strong{
        font-size: 15px;
      }
      .blogPara .paraImage{
        width: 32.4rem;
        height: 32.4rem;
      }
      @media screen and (max-width: 600px) {
        .blogPara .paraImage{ 
          max-width: 100%;
          height: auto;
        }
         
      } 
      @media   (max-width:900px) {
        /* .BlogShare {
          display: block !important;
          text-align: center;
        }
        .pad-2{
          padding-left: 0.25rem!important;
          padding-right: 0.25rem!important;
        }
         */
         .mt-tab-5{
          margin-top: 3rem!important;
         }
         .first{
          order: 1;
         }
         .second{
          order: 2;
         }
      }
      @media screen and (min-width:601px )and (max-width: 900px) {
        .blogPara .paraImage{ 
          width: 30rem;
          height: 30rem;
        }
        .blogPara .para-layout-img{ 
          width: 30rem;
          height: 30rem;
        }
      }
      .blog-content .border{
        border: 1px solid #dee2e6 !important;
      }
      .fontsize{
        font-size: 2.6rem!important;
      }
      .blogShareIcons .fa
      {
        color: #7854F7;
        font-size: 25px;
      
      }
      .blogShareIcons .fa-link{
        cursor: pointer;
      }
      .blog-heading{
        height: auto;
        padding: 8em 0;
      }
      .site-page__heading {
       color:#211f72;
        font-size: 2.188rem;
        font-weight: bolder;
        letter-spacing: -0.13px;
        text-transform: uppercase;
      }
      .background-header{
          background-size: cover !important;
          background-position: 50% 50% !important;
          z-index: 0;
      }
      .blog-img-card-top{
        height: 8.75rem;
      }
      .CopyBtn {
        height: 27px;
        width: 27px;
    }
      .zindex{
        z-index: 2;
      }
      .back-icon{
        color: #7854F7;
        font-size: 1.031rem;
        cursor: pointer;
      }
      .back-icon span{
        font-family: "Raleway", sans-serif;
        font-size: 0.9rem;
      }
      .padding-top{
        padding-top: 5rem;
      }
      .padding-right{
        padding-right: 1.9rem!important;
      }
      .margin-top {
        margin-top: 2rem;
      }
      .display{
      display: none;
      }
      @media (min-width:600px) {
      .margin-top{
        margin-top: 16rem;
      }
      .display{
      display: unset;
      }
      } 
      /*blog card*/
      .blog-card .blog-card-content{
        padding-top :1.25rem!important;
        padding-bottom: 1.25rem!important;
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
      }
      .blog-card .blog-image{
      margin-bottom: 1rem;
      height:10.8rem
      }
      .blog-card .blog-title{
        font-size: 17px;
      }
      .blog-card .blog-desc{
        font-size: 14px;
      }
      .blog-card .link-blog{
        text-decoration: none!important;
      }
      .main-container1{
        padding: 88px 15px 50px 15px;
        min-height: 85vh;
      }
      .resourceSubHeading {
        font-size: 2.188rem;
        font-weight: bold;
        color: #182026 !important;
    }
    .card-pera1 p {
        font-size: 12.5px !important;
        line-height: normal !important;
        margin-bottom: 10px;
    }
    .home-in {
        text-decoration: none !important;
    }
    .hovera:hover{
        font-size: 14px;
        font-weight: 600;
        text-decoration: none!important;
      }
      .hovera{
        color:#7854F7;
      }
      .py-6{
        padding-top: 4.2rem;
        padding-bottom: 3rem;
      }
    }
    .main-container {
      position: relative;
      padding: 115px 15px 60px 15px !important;
      height: 90vh;
    }