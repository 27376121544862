.pos-rel{
    position: relative;
}
#apidocs{
    #pos-sticky{
        position: sticky!important;
        top: 50px;
        position: -webkit-sticky;
    }
 .borderbottomtop{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
 }
 .commonRow{
    text-align: center;
    align-items: center;
 }
 .width{
    width: 100px!important;
 }
 .width2{
    width: 140px;
 }
 .copy{
    font-size: 15px;
    cursor: pointer;
    position: absolute;
    right: 52px;
 }
 .mt25rem{
    margin-top: 2.5rem;
 }
    .req{
        height: 21rem;
        overflow: auto;
    }
    .req2{
      height: 10rem;
    overflow: auto;
    }
    .apidoccontainer{
        margin-left: 10rem;
        margin-right: 10rem;
    }
    .apiborderbox{
        border: 1px solid #a2a2a5;;
        border-radius: 0.25rem;
        
    }
    .px-5-api{
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
    @media only screen and (max-width:480px){
        .px-5-api{
            padding-left: 0rem!important;
            padding-right: 0rem!important;
        }
        .pb-18 {
            padding-bottom: 0px !important;
        }
        .pt-18 {
            padding-top: 0px;
        }
      }
      .width3{
        width: 15rem;
      }
      .h10rem{
      height: 10rem!important;
      }
    .h32rem {
        height: 32rem!important;
    }
    @media only screen and (max-width:768px){
        .h32rem{
            height: 21rem!important;
        }
    }
    .nav-tabs .nav-link.active{
        background-color: #2E3336;
    color: #fff;
    font-weight: bold;
    border: none;
    }
    .api-tab-container{
        background-color: #1E2224;
        a{
            color: #fff;
        }
    }
   
    .tab-content{
        background-color: #2E3336;
        height: 43rem;
       overflow: auto;
       padding: 1.5rem;
        color: #fff;
       pre{
        overflow:unset!important;
       }
    }
    .codeAPI{
        background-color: #2E3336!important;
    }
    .sidebar {
        margin: 0;
        padding: 0;
        background-color: #fff;
        /* width: auto !important; */
      }
      
      .sidebar a {
        display: block;
        color: black;
        padding: 16px;
        text-decoration: none;
        font-size: 1rem;
      }
      
      .sidebar a.active {
        background-color: #4CAF50;
        color: white;
        font-weight: bold;
      }
      
      .sidebar a:hover:not(.active) {
        background-color: #5A50C6;
        color: white;
      }
      .activeMenu {
        background-color:#e4e4e4;
        font-weight: bold;
      }
      img.vi-info-img {
        width: 75%;
        height: auto;
      }
      
      .vi-info-p {
        font-weight: 500;
        line-height: 1.75;
        color: #131415;
      }
      
      .vi-info-p iframe {
        width: 99% !important;
        height: 545px;
      }
      
      .vi-info-p p,
      .vi-info-p ul li,
      .vi-info-p ol li {
        font-size: 13px;
        text-align: justify;
      }
      
      .vi-info-blue-block {
        font-size: 30px;
        height: 55px;
        line-height: 22px;
        padding: 15px 0;
        width: 55px;
        font-weight: 500;
        margin-top: 5px;
        float: left;
        margin: 0 20px 0 0;
        text-align: center;
        background-color: rgb(27, 50, 114);
        color: #fff;
      }      
      pre {
        background: #F2EFF9;
        padding: 15px;
        font-size: 14px;
      }
      .section-pb-50 {
        padding-bottom: 50px!important;
      }
      .font-size-25 {
        font-size: 25px;
    }
    .section-pb-50 .table {
      font-size: 12px;
  }
  .font-color-grey {
    color: #999999;
}
.font-size-14 {
    font-size: 14px;
}
.pb-18 {
  padding-bottom: 18px !important;
}
}